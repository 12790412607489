<template>
	<div class="userinfo">
		<div class="my-title">
			<p>我的信息</p>
		</div>

		<el-form
			:model="ruleForm"
			status-icon
			:rules="rules"
			ref="ruleForm"
			class="demo-ruleForm"
			label-position="rigth"
			label-width="140px"
		>
			<el-form-item
				label="姓名:"
				:rules="[{ required: true, message: '姓名不能为空' }]"
				prop="cooperationPresidentName"
			>
				<el-input
					style="width: 50%"
					placeholder="请输入姓名"
					v-model="ruleForm.cooperationPresidentName"
				></el-input>
			</el-form-item>
			<el-form-item label="所属网点:">
				<div
					style="
						width: 50%;
						height: 40px;
						border-radius: 4px;
						box-sizing: border-box;
						padding: 0 15px;
						color: #606266;
					"
				>
					<span v-if="cooperationNetworkName">{{
						cooperationNetworkName
					}}</span>
					<span v-else style="color: rgb(193 193 195)"
						>请选择所属网点</span
					>
				</div>
			</el-form-item>
			<el-form-item label="金融机构:">
				<div
					style="
						width: 50%;
						height: 40px;
						border-radius: 4px;
						box-sizing: border-box;
						padding: 0 15px;
						color: #606266;
					"
				>
					<span v-if="financialOrganizationsName">{{
						financialOrganizationsName
					}}</span>
					<span v-else style="color: rgb(193 193 195)"
						>请选择金融机构</span
					>
				</div>
			</el-form-item>
			<el-form-item
				:rules="[{ required: true, message: '职务不能为空' }]"
				label="职务:"
				prop="post"
			>
				<el-select
					style="width: 50%"
					v-model="ruleForm.post"
					placeholder="请选择职务"
				>
					<el-option
						v-for="item in CooperationPresidentPost"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				:rules="[{ required: true, message: '请选择性别' }]"
				label="性别:"
				prop="sex"
			>
				<el-radio
					v-for="item in SexFlag"
					v-model="ruleForm.sex"
					:label="item.value"
					:key="item.value"
					>{{ item.label }}</el-radio
				>
			</el-form-item>
			<el-form-item label="联系电话:" prop="telephone">
				<el-input
					style="width: 50%"
					placeholder="请输入联系电话"
					v-model="ruleForm.telephone"
				></el-input>
				<p style="color: red; position: absolute; left: 52%; top: 0">
					修改联系电话则登录账号也会同步修改
				</p>
			</el-form-item>
			<el-form-item
				:rules="[{ required: true, message: '负责业务不能为空' }]"
				label="负责业务:"
				prop="responsibleBusiness"
			>
				<el-select
					style="width: 50%"
					v-model="ruleForm.responsibleBusiness"
					placeholder="请选择负责业务"
				>
					<el-option
						v-for="item in ResponsibleBusiness"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="生日:">
				<el-form-item prop="birthday">
					<el-date-picker
						type="date"
						placeholder="请选择生日时间"
						v-model="ruleForm.birthday"
						style="width: 50%"
						value-format="yyyy-MM-dd"
					></el-date-picker>
				</el-form-item>
			</el-form-item>
			<el-form-item label="办公地址:">
				<el-input
					style="width: 50%"
					placeholder="请输入办公地址"
					v-model="ruleForm.officeAddress"
				></el-input>
			</el-form-item>
			<el-form-item label="家庭地址:">
				<el-input
					style="width: 50%"
					placeholder="请输入家庭地址"
					v-model="ruleForm.homeAddress"
				></el-input>
			</el-form-item>

			<el-form-item label="客户类型偏好:">
				<el-checkbox-group v-model="ruleForm.customerTypePreference">
					<el-checkbox
						v-for="(item, index) in CustomerTypePreference"
						:key="index"
						:label="item.value"
						>{{ item.label }}</el-checkbox
					>
				</el-checkbox-group>
			</el-form-item>

			<el-form-item label="授信产品偏好:">
				<el-checkbox-group v-model="ruleForm.creditProductsPreference">
					<el-checkbox
						v-for="(item, index) in CreditProductsPreference"
						:key="index"
						:label="item.value"
						>{{ item.label }}</el-checkbox
					>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="授信担保方式偏好:">
				<el-checkbox-group
					v-model="ruleForm.creditGuaranteeMethodPreference"
				>
					<el-checkbox
						v-for="(item, index) in CreditGuaranteeMethodPreference"
						:key="index"
						:label="item.value"
						>{{ item.label }}</el-checkbox
					>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="非抵押物偏好:">
				<el-checkbox-group
					v-model="ruleForm.nonStandardCollateralPreference"
				>
					<el-checkbox
						v-for="(item, index) in NonStandardCollateralPreference"
						:key="index"
						:label="item.value"
						>{{ item.label }}</el-checkbox
					>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="客户地域偏好:">
				<el-radio-group
					v-model="ruleForm.customerGeographicalPreference"
				>
					<el-radio
						v-for="item in CustomerGeographicalPreference"
						v-model="ruleForm.customerGeographicalPreference"
						:label="item.value"
						:key="item.value"
						>{{ item.label }}</el-radio
					>
				</el-radio-group>
			</el-form-item>
			<el-form-item label="综合业务偏好:">
				<el-checkbox-group
					v-model="ruleForm.comprehensiveBusinessPreference"
				>
					<el-checkbox
						v-for="(item, index) in ComprehensiveBusinessPreference"
						:key="index"
						:label="item.value"
						>{{ item.label }}</el-checkbox
					>
				</el-checkbox-group>
			</el-form-item>

			<el-form-item style="width: 80%" label="名片附件:">
				<el-upload
					:headers="{
						token: $store.state.userInfo.token,
					}"
					class="upload-demo"
					name="files"
					:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
					:on-success="
						(file, fileList) => handleChange(file, fileList)
					"
					:on-remove="
						(file, fileList, index) =>
							handleRemove(file, fileList, index)
					"
					:on-preview="dowloadFile"
					multiple
					:limit="3"
					:file-list="ruleForm.businessCardAttachment"
				>
					<el-button size="small" type="primary">点击上传</el-button>
				</el-upload>
			</el-form-item>

			<el-form-item label="补充说明:">
				<el-input
					type="textarea"
					:autosize="{ minRows: 3, maxRows: 14 }"
					placeholder="请输入内容"
					v-model="ruleForm.supplementaryNotes"
					style="width: 80%"
				>
				</el-input>
			</el-form-item>
		</el-form>

		<div
			style="
				display: flex;
				justify-content: space-around;
				padding: 100px 0;
			"
		>
			<el-button type="primary" @click="submitForm('ruleForm')"
				>保存我的个人信息</el-button
			>
		</div>

		<branchDialog
			@selectNetworkList="selectNetworkList"
			:dialogVisible.sync="dialogVisible"
		/>
	</div>
</template>

<script>
import {
	itemByType,
	updateCooperationPresident,
	readCooperationPresident,
} from '@/api/index.js';
import branchDialog from './module/branchDialog.vue';
export default {
	name: 'userinfo',
	data() {
		const checkUserName = (rule, value, callback) => {
			let reg = /^1[345789]\d{9}$/;
			if (!reg.test(value)) {
				callback(new Error('请输入正确的手机号'));
			} else {
				callback();
			}
		};
		return {
			dialogVisible: false,
			rules: {
				telephone: [
					{
						required: true,
						validator: checkUserName,
						trigger: 'blur',
					},
				],
			},
			ruleForm: {
				cooperationPresidentName: '',
				post: '',
				sex: '',
				telephone: '',
				responsibleBusiness: '',
				birthday: '',
				officeAddress: '',
				homeAddress: '',
				customerTypePreference: [],
				creditProductsPreference: [],
				creditGuaranteeMethodPreference: [],
				nonStandardCollateralPreference: [],
				customerGeographicalPreference: '',
				comprehensiveBusinessPreference: [],
				businessCardAttachment: [],
				supplementaryNotes: '',
			},
			cooperationNetworkName: '',
			financialOrganizationsName: '',
			CooperationPresidentPost: [],
			SexFlag: [],
			CustomerTypePreference: [],
			CreditProductsPreference: [],
			CreditGuaranteeMethodPreference: [],
			NonStandardCollateralPreference: [],
			CustomerGeographicalPreference: [],
			ComprehensiveBusinessPreference: [],
			ResponsibleBusiness: [],
		};
	},
	components: {
		branchDialog,
	},
	methods: {
		dowloadFile(res) {
			location.href =
				this.$baseUrl +
				'api/file/fileDownloadCommon.do?path=' +
				res.url;
		},
		handleChange(file, fileList, name) {
			this.ruleForm.businessCardAttachment.push({
				name: file.list[0].fileName,
				url: file.list[0].filePath,
			});
		},
		handleRemove(file, fileList, index, name) {
			this.ruleForm.businessCardAttachment.splice(index, 1);
		},
		async getGradeList(name) {
			const res = await itemByType({
				dictionaryType: name,
			});
			if (res.returncode === 0) {
				this[name] = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
		},
		selectNetworkList(data) {
			this.ruleForm.cooperationNetworkId = data[0].cooperationNetworkId;
			this.ruleForm.cooperationNetworkName =
				data[0].cooperationNetworkName;
			this.ruleForm.financialOrganizationsId =
				data[0].financialOrganizationsId;
			this.ruleForm.financialOrganizationsName =
				data[0].financialOrganizationsName;
		},
		submitForm(formName) {
			this.$refs[formName].validate(async (valid) => {
				if (valid) {
					const data = JSON.parse(JSON.stringify(this.ruleForm));

					data.businessCardAttachment = JSON.stringify(
						data.businessCardAttachment.map((item) => ({
							fileName: item.name,
							filePath: item.url,
						}))
					);
					data.customerTypePreference =
						data.customerTypePreference.join();
					data.creditProductsPreference =
						data.creditProductsPreference.join();
					data.creditGuaranteeMethodPreference =
						data.creditGuaranteeMethodPreference.join();
					data.nonStandardCollateralPreference =
						data.nonStandardCollateralPreference.join();
					data.comprehensiveBusinessPreference =
						data.comprehensiveBusinessPreference.join();
					data.cooperationPresidentId =
						this.$store.state.userInfo.userVo.cooperationPresidentId;
					const res = await updateCooperationPresident(data);
					if (res.returncode == 0) {
						this.$message({
							message: '保存成功',
							type: 'success',
						});
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		async getReadCooperationPresident() {
			const res = await readCooperationPresident({
				cooperationPresidentId:
					this.$store.state.userInfo.userVo.cooperationPresidentId,
			});
			if (res.businessCardAttachment) {
				res.businessCardAttachment = JSON.parse(
					res.businessCardAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				res.businessCardAttachment = [];
			}
			res.customerTypePreference = res.customerTypePreference
				? res.customerTypePreference.split(',')
				: [];
			res.creditProductsPreference = res.creditProductsPreference
				? res.creditProductsPreference.split(',')
				: [];
			res.creditGuaranteeMethodPreference =
				res.creditGuaranteeMethodPreference
					? res.creditGuaranteeMethodPreference.split(',')
					: [];
			res.nonStandardCollateralPreference =
				res.nonStandardCollateralPreference
					? res.nonStandardCollateralPreference.split(',')
					: [];
			res.comprehensiveBusinessPreference =
				res.comprehensiveBusinessPreference
					? res.comprehensiveBusinessPreference.split(',')
					: [];
			res.sex = res.sex ? res.sex + '' : '';
			res.post = res.post ? res.post + '' : '';
			res.customerGeographicalPreference =
				res.customerGeographicalPreference
					? res.customerGeographicalPreference + ''
					: '';
			res.responsibleBusiness = res.responsibleBusiness
				? res.responsibleBusiness + ''
				: '';

			res.responsibleBusiness = res.responsibleBusiness
				? res.responsibleBusiness + ''
				: '';

			this.financialOrganizationsName =
				res.financialOrganizationsName || res.registerOrganization;
			this.cooperationNetworkName =
				res.cooperationNetworkName || res.registerNetwork;

			this.ruleForm = res;
		},
	},
	created() {
		// 查询信息
		this.getReadCooperationPresident();
		// 职务
		this.getGradeList('CooperationPresidentPost');
		// 性别
		this.getGradeList('SexFlag');
		// 客户类型偏好
		this.getGradeList('CustomerTypePreference');
		// 授信产品偏好
		this.getGradeList('CreditProductsPreference');
		// 授信担保方式偏好
		this.getGradeList('CreditGuaranteeMethodPreference');
		// 非抵押物偏好
		this.getGradeList('NonStandardCollateralPreference');
		// 客户地域偏好
		this.getGradeList('CustomerGeographicalPreference');
		// 综合业务偏好
		this.getGradeList('ComprehensiveBusinessPreference');
		// 负责业务
		this.getGradeList('ResponsibleBusiness');

		// 金融
		// this.getFinancialOrganizetionList();
	},
};
</script>
<style lang="scss" scoped>
.userinfo {
	width: 100%;
	height: 100%;
}
</style>
