<template>
	<el-dialog
		title="合作网点选择"
		:visible.sync="dialogVisible"
		width="50%"
		:before-close="
			() => {
				$emit('update:dialogVisible', false);
				$refs.multipleTable.clearSelection();
			}
		"
	>
		<div>
			<span>网点简称:</span>
			<el-input
				style="width:20%;margin:0 10px;"
				placeholder="请输入网点简称"
				v-model="params.searchNetworkSimpleName"
			></el-input>
			<span>金融机构:</span>
			<el-input
				style="width:20%;margin:0 10px;"
				placeholder="请输入金融机构"
				v-model="params.searchFinancialOrganizationsName"
			></el-input>

			<el-button
				type="primary"
				@click="
					() => {
						params.searchNetworkSimpleName = '';
						params.searchFinancialOrganizationsName = '';
						params.pageNo = 1;
						getNetworkList();
					}
				"
				>清空条件</el-button
			>
			<el-button
				type="primary"
				@click="
					() => {
						params.pageNo = 1;
						getNetworkList();
					}
				"
				>查询</el-button
			>
		</div>
		<div style="padding:20px 0">
			<el-table
				border
				height="250"
				ref="multipleTable"
				:data="branchList"
				tooltip-effect="dark"
				style="width: 100%"
				highlight-current-row
				:header-cell-style="{ background: '#ecf5ff' }"
				@selection-change="handleSelectionChange"
				@row-click="
					(row) => $refs.multipleTable.toggleRowSelection(row)
				"
			>
				<el-table-column type="selection" width="55"> </el-table-column>
				<el-table-column label="网点简称" prop="networkSimpleName">
				</el-table-column>
				<el-table-column
					width="300"
					prop="financialOrganizationsName"
					label="金融机构"
				>
				</el-table-column>
				<el-table-column prop="alias" label="网点经理">
				</el-table-column>
				<el-table-column label="网点竞争力">
					<template slot-scope="scope"
						>{{
							scope.row.networkCompetitivenessScore || 0
						}}星</template
					>
				</el-table-column>
			</el-table>
		</div>
		<div style="text-align: end;">
			<el-pagination
				@current-change="currentChange"
				background
				layout="prev, pager, next"
				:total="total"
			>
			</el-pagination>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="$emit('update:dialogVisible', false)"
				>取 消</el-button
			>
			<el-button
				type="primary"
				@click="
					() => {
						$emit('update:dialogVisible', false);
						$emit('selectNetworkList', multipleSelection);
						$refs.multipleTable.clearSelection();
					}
				"
				>确 定</el-button
			>
		</span>
	</el-dialog>
</template>

<script>
import { networkList } from '@/api/index.js';
export default {
	name: 'branchDialog',
	props: {
		dialogVisible: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			branchList: [],
			multipleSelection: [],
			params: {
				pageSize: 10,
				pageNo: 1,
				sortFlag: 'networkCompetitivenessScore-desc',
				searchNetworkSimpleName: '',
				searchFinancialOrganizationsName: '',
			},
			total: 0,
		};
	},
	methods: {
		// handleClose(done) {
		// 	done();

		// 	// this.$confirm('确认关闭？')
		// 	// 	.then((_) => {
		// 	// 		done();
		// 	// 	})
		// 	// 	.catch((_) => {});
		// },
		async getNetworkList() {
			const res = await networkList(this.params);
			if (res.returncode === 0) {
				this.branchList = res.list;
				this.total = res.totalRows;
			}
		},
		toggleSelection(rows) {
			if (rows) {
				rows.forEach((row) => {
					this.$refs.multipleTable.toggleRowSelection(row);
				});
			} else {
				this.$refs.multipleTable.clearSelection();
			}
		},
		handleSelectionChange(val) {
			if (val.length > 1) {
				this.$refs.multipleTable.clearSelection(); //清除
				this.$refs.multipleTable.toggleRowSelection(val.pop()); //pop：最后一个
			} else {
				this.multipleSelection = val; //multipleSelection：当前选择的数组
			}
		},
		currentChange(val) {
			this.params.pageNo = val;
			this.getNetworkList();
		},
	},
	created() {
		// 网点
		this.getNetworkList();
	},
};
</script>
<style lang="scss" scoped>
::v-deep thead {
	.el-table-column--selection {
		.cell {
			display: none;
		}
	}
}
</style>
